import React from 'react';
import { Redirect } from 'react-router-dom';

import dashboardRoutes from './views/dashboard/DashboardRoutes';
import utilitiesRoutes from './views/utilities/UtilitiesRoutes';
import sessionRoutes from './views/sessions/SessionRoutes';

import profileRoutes from './views/profile/profileRoutes';
import materialRoutes from './views/material-kit/MaterialRoutes';

import customRoutes from './views/clientes/ClientRoutes';
import companyRoutes from './views/empresas/CompanyRoutes';
import animaisRoutes from './views/animais/AnimaisRoutes';
import comissoesRoutes from './views/comissoes/ComissoesRoutes';
import usuariosRoutes from './views/usuarios/UsuariosRoutes';
import freteRoutes from './views/frete/FreteRoutes';
import appRoutes from './views/app/AppRoutes';

import categoriasRoutes from './views/categorias/CategoriasRoutes';

import lojistaRoutes from './views/lojista/LojistaRoutes';

import deliveryRoutes from './views/delivery/DeliveryRoutes';
import tipoServicoRoutes from './views/tiposervico/TipoServicoRoutes';
import motoristaRoutes from './views/motoristas/MotoristasRoutes';
import relatorioRoutes from './views/relatorio/RelatorioRoutes';
import produtoRoutes from './views/produtosunique/ProdutoRoutes';
import transmissaoRoutes from './views/transmissao/TransmissaoRoutes';
import chatRoutes from './views/chat/ChatRoutes';

const redirectRoute = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/dashboard/analytics" />,
  },
];

const errorRoute = [
  {
    component: () => <Redirect to="/session/404" />,
  },
];

const routes = [
  ...profileRoutes,
  ...sessionRoutes,
  ...dashboardRoutes,
  ...materialRoutes,
  ...customRoutes,
  ...companyRoutes,
  ...animaisRoutes,
  ...comissoesRoutes,
  ...usuariosRoutes,
  ...appRoutes,
  ...freteRoutes,
  ...deliveryRoutes,
  ...tipoServicoRoutes,
  ...categoriasRoutes,
  ...lojistaRoutes,
  ...motoristaRoutes,
  ...relatorioRoutes,
  ...produtoRoutes,
  // ...utilitiesRoutes,
  ...transmissaoRoutes,
  ...chatRoutes,
  ...redirectRoute,
  ...errorRoute,
];

export default routes;
