import { MatxLoadable } from "matx";

const Table = MatxLoadable({
  loader: () => import("./listagem/Table")
});

const Form = MatxLoadable({
  loader: () => import("./cadastro/Form")
});

const animaisRoutes = [
  {
    path: "/animais",
    component: Table
  },
  {
    path: "/novoAnimal",
    component: Form
  },
  {
    path: "/editarAnimal/:id",
    component: Form
  }
]

export default animaisRoutes;