import { MatxLoadable } from 'matx';

const Configuracoes = MatxLoadable({
  loader: () => import('./configuracoes/Form'),
});

const Pedidos = MatxLoadable({
  loader: () => import('./pedidos/Table'),
});

const Detalhes = MatxLoadable({
  loader: () => import('./pedidos/Details'),
});

const Produtos = MatxLoadable({
  loader: () => import('./produtosListagem/Table'),
});

const BaseUnica = MatxLoadable({
  loader: () => import('./base/Table'),
});

const ProdutosNovo = MatxLoadable({
  loader: () => import('./produtosCadastro/Form'),
});

const Estoque = MatxLoadable({
  loader: () => import('./estoque/Table'),
});

const Vendas = MatxLoadable({
  loader: () => import('./vendas/Form'),
});

const CaixaDiario = MatxLoadable({
  loader: () => import('./caixaDiario/Table'),
});

const ServicosCadastro = MatxLoadable({
  loader: () => import('./servicos/Form'),
});
const ServicosEditar = MatxLoadable({
  loader: () => import('./servicos/Form'),
});

const Servicos = MatxLoadable({
  loader: () => import('./servicos/Table'),
});

const Agendamentos = MatxLoadable({
  loader: () => import('./servicos/Agendamentos'),
});

const ServicoSingle = MatxLoadable({
  loader: () => import('./servicos/Single'),
});

const Historico = MatxLoadable({
  loader: () => import('./historico/Table'),
});

const lojistaRoutes = [
  {
    path: '/configuracoes',
    component: Configuracoes,
  },
  {
    path: '/pedidos',
    component: Pedidos,
  },
  {
    path: '/detalhesPedido/:id/:origin',
    component: Detalhes,
  },
  {
    path: '/produtos',
    component: Produtos,
  },
  {
    path: '/estoque',
    component: Estoque,
  },
  {
    path: '/novoProduto',
    component: ProdutosNovo,
  },
  {
    path: '/editarProduto/:id',
    component: ProdutosNovo,
  },
  {
    path: '/vendas',
    component: Vendas,
  },
  {
    path: '/caixaDiario',
    component: CaixaDiario,
  },
  {
    path: '/servicos',
    component: Servicos,
  },
  {
    path: '/servico/:id',
    component: ServicoSingle,
  },
  {
    path: '/servicoscadastro',
    component: ServicosCadastro,
  },
  {
    path: '/servicosEditar/:id',
    component: ServicosEditar,
  },
  {
    path: '/agendamentos',
    component: Agendamentos,
  },
  {
    path: '/vendaHistorico',
    component: Historico,
  },
  {
    path: '/base',
    component: BaseUnica,
  },
];

export default lojistaRoutes;
