import { MatxLoadable } from "matx";

const Table = MatxLoadable({
  loader: () => import("./listagem/Table")
});

const Form = MatxLoadable({
  loader: () => import("./cadastro/Form")
});

const tipoServicoRoutes = [
  {
    path: "/tiposervico",
    component: Table
  },
  {
    path: "/novoTipoServico",
    component: Form
  },
  {
    path: "/editarTipoServico/:id",
    component: Form
  }
]

export default tipoServicoRoutes;