import { MatxLoadable } from "matx";

const Table = MatxLoadable({
  loader: () => import("./listagem/Table")
});

const Form = MatxLoadable({
  loader: () => import("./cadastro/Form")
});

const clientRoutes = [
  {
    path: "/cliente",
    component: Table
  },
  {
    path: "/novoCliente",
    component: Form
  }
]

export default clientRoutes;