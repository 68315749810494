import { MatxLoadable } from 'matx';

const Listagem = MatxLoadable({
  loader: () => import('./listagem/Table'),
});

const Cadastro = MatxLoadable({
  loader: () => import('./cadastro/Form'),
});

const lojistaRoutes = [
  {
    path: '/produtosUnique',
    component: Listagem,
    exact: true,
  },
  {
    path: '/produtosUnique/cadastro',
    component: Cadastro,
    exact: true,
  },
  {
    path: '/produtosUnique/editar/:id',
    component: Cadastro,
    exact: true,
  },
];

export default lojistaRoutes;
