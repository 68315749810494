import React, { useState, useRef, useEffect } from 'react';
import {
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';

import axios from 'app/middleware/axios';
import history from 'history.js';
import CONSTANTS from '../../utils/constants';

import * as Sentry from "@sentry/react";

import SweetAlert from 'sweetalert2-react';
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';

const SignUp = () => {
  const [razaoSocial, setRazaoSocial] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [nomeFantasia, setNomeFantasia] = useState('');
  const [telefone, setTelefone] = useState('');
  const [cep, setCep] = useState('');
  const [numero, setNumero] = useState('');
  const [endereco, setEndereco] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('');
  const [estado, setEstado] = useState('');
  const [pais, setPais] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [complemento, setComplemento] = useState('');
  const [termos, setTermos] = useState(false);
  const [show, setShow] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(null);

  const formRef = useRef('Form');

  const handleChangeCEP = (e) => {
    let valor = e.target.value
                        .replace(/\D/g, '')
                        .replace(/(\d{5})(\d)/, '$1-$2')
                        .replace(/(-\d{3})\d+?$/, '$1');
    setCep(valor);
  };

  const handleChangePhone = (e) => {
    let valor = e.target.value
                        .replace(/\D/g, '')
                        .replace(/(\d{2})(\d)/, '($1) $2')
                        .replace(/(\d{4})(\d)/, '$1-$2')
                        .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
                        .replace(/(-\d{4})\d+?$/, '$1');
    setTelefone(valor);
  };

  const handleChangeCNPJ = (e) => {
    let valor = e.target.value
                        .replace(/\D/g, '')
                        .replace(/(\d{2})(\d)/, '$1.$2')
                        .replace(/(\d{3})(\d)/, '$1.$2')
                        .replace(/(\d{3})(\d)/, '$1/$2')
                        .replace(/(\d{4})(\d)/, '$1-$2')
                        .replace(/(-\d{2})\d+?$/, '$1');
    setCnpj(valor);
  };

  useEffect(() => {
    if (value) {
      geocodeByAddress(value.value.description)
        .then(results => {
          setEndereco(results[0].formatted_address);
          
          setLogradouro(results[0].address_components.filter((key) => key.types.includes('route'))[0]);
          setBairro(results[0].address_components.filter((key) => key.types.includes('sublocality_level_1'))[0]);
          setCidade(results[0].address_components.filter((key) => key.types.includes('administrative_area_level_2'))[0]);
          setEstado(results[0].address_components.filter((key) => key.types.includes('administrative_area_level_1'))[0]);
          setPais(results[0].address_components.filter((key) => key.types.includes('country'))[0]);
          setNumero(results[0].address_components.filter((key) => key.types.includes('street_number'))[0]);
          setCep(results[0].address_components.filter((key) => key.types.includes('postal_code'))[0].short_name);

          getLatLng(results[0])
            .then(({ lat, lng }) => {
              setLatitude(lat);
              setLongitude(lng);
            });
        })
    }  
    // eslint-disable-next-line react-hooks/exhaustive-deps  
  },[value]);

  const onSubmit = data => {
    if (!termos) {
      setAlertMessage('Aceite os termos de uso!');
      setShow(true);
      return;
    }
    if (latitude === '') {
      setAlertMessage('Digite o endereço!');
      setShow(true);
      return;
    }
    setLoading(true);

    const user = {
      telefone: telefone.replace(/\D/g, ''),
      cnpj: cnpj.replace(/\D/g, ''),
      senha: password,
      email,
      endereco,
      cep: cep.replace(/\D/g, ''),
      logradouro: logradouro.short_name || '',
      bairro: bairro.short_name || '',
      numero: numero.short_name || 0,
      cidade: cidade.short_name || '',
      estado: estado.short_name || '',
      pais: pais.short_name || 'BRA',
      complemento,
      latitude,
      longitude,
      comissao: null,
      estabelecimento_aberto: 0,
      ativo: 2,
      situacao: 'Em Análise',
      data_aprovacao: null,
      motivo_reprovacao: null,
      id_arquivo: null,
      nomeFantasia,
      razaoSocial,
      telefoneComercial: telefone.replace(/\D/g, ''),
      idRepresentante: null,
      horario_abertura: null,
      horario_fechamento: null,
      tiposAnimais: null,
      image: null,
      permissionLevel: 1,
      formasPagamento: 'dinheiro,pagamento_entrega,credito_online',
      isFreteProprio: false,
      freteProprio: null,
    };

    console.log(user);
    axios
      .post(`${CONSTANTS.endpoint}/preCadastroLojista`, { user })
      .then(response => {
        setLoading(false);
        alert('Pré-cadastro efetuado!');
        history.push('/session/signin');
      })
      .catch(error => {
        Sentry.captureMessage(error.response.data.message || 'Erro ao efetuar cadastro');
        setAlertMessage(
          error.response.data.message || 'Erro ao efetuar cadastro'
        );
        setLoading(false);
        setShow(true);
      })
      .then(() => {});
  };

  return (
    <div className="signup flex flex-center w-100 h-100vh">
      <SweetAlert
        show={show}
        title="Erro"
        text={alertMessage}
        onConfirm={() => setShow(false)}
      />
      <div className="p-8">
        <Card className="signup-card position-relative y-center">
          <ValidatorForm ref={formRef} onSubmit={onSubmit}>
            <Grid container>
              <Grid
                className="horizontalPadding-32 grid-image"
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
              >
                <img src="/assets/images/logo_branca.png" alt="" />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <div className="p-36 h-100">
                  <TextValidator
                    className="mb-24 w-100"
                    variant="outlined"
                    label="Razão Social"
                    onChange={e => setRazaoSocial(e.target.value)}
                    type="text"
                    name="razaoSocial"
                    value={razaoSocial}
                    validators={['required']}
                    errorMessages={['Campo obrigatório!']}
                  />
                  
                  <TextValidator
                    className="mb-24 w-100"
                    variant="outlined"
                    label="CNPJ"
                    onChange={e => handleChangeCNPJ(e)}
                    type="text"
                    name="cnpj"
                    value={cnpj}
                    validators={['required']}
                    errorMessages={['Campo obrigatório!']}
                  />
                  <TextValidator
                    className="mb-24 w-100"
                    variant="outlined"
                    label="Email"
                    onChange={e => setEmail(e.target.value)}
                    type="email"
                    name="email"
                    value={email}
                    validators={['required', 'isEmail']}
                    errorMessages={['Campo obrigatório!', 'email is not valid']}
                  />
                  <div className="mb-24 w-100">
                    <span>Digite o Endereço</span>
                    <GooglePlacesAutocomplete
                      apiKey="AIzaSyAoGlj5FtV63CbvnAAzthxcJVBJDOLumgY"
                      selectProps={{
                        value,
                        onChange: setValue,
                      }}
                    />
                  </div>
                  <TextValidator
                    className="mb-24 w-100"
                    variant="outlined"
                    label="Complemento"
                    onChange={e => setComplemento(e.target.value)}
                    type="complemento"
                    name="complemento"
                    value={complemento}
                    onFocus={(event) => {
                      event.target.setAttribute('autocomplete', 'asdasd');
                    }}
                  />
                </div>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <div className="p-36 h-100">
                  <TextValidator
                    className="mb-24 w-100"
                    variant="outlined"
                    label="Nome Fantasia"
                    onChange={e => setNomeFantasia(e.target.value)}
                    type="text"
                    name="scapets_nF"
                    value={nomeFantasia}
                    validators={['required']}
                    errorMessages={['Campo obrigatório!']}
                  />
                  <TextValidator
                    className="mb-24 w-100"
                    variant="outlined"
                    label="Telefone"
                    onChange={e => handleChangePhone(e)}
                    type="text"
                    name="telefone"
                    value={telefone}
                    validators={['required']}
                    errorMessages={['Campo obrigatório!']}
                    onFocus={(event) => {
                      event.target.setAttribute('autocomplete', 'asdasd');
                    }}
                  />
                  <TextValidator
                    className="mb-16 w-100"
                    label="Senha"
                    variant="outlined"
                    onChange={e => setPassword(e.target.value)}
                    name="password"
                    type="password"
                    value={password}
                    validators={['required']}
                    errorMessages={['Campo obrigatório!']}
                  />
                  <TextValidator
                    className="mb-16 w-100"
                    label="CEP"
                    variant="outlined"
                    onChange={(e) => handleChangeCEP(e)}
                    name="cep"
                    type="text"
                    value={cep}
                    validators={['required']}
                    errorMessages={['Campo obrigatório!']}
                    onFocus={(event) => {
                      event.target.setAttribute('autocomplete', 'asdasd');
                    }}
                  />
                </div>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <div className="horizontalPadding-32 bottomPadding-32 h-100">
                  <a
                    className="linkTermos"
                    href="https://scapets.com.br/assets/img/documentos/CONTRATO-LOJISTAS-SCAPETS.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ler os Termos e condições de uso
                  </a>

                  <br />
                  <FormControlLabel
                    className="mb-16"
                    name="termos"
                    onChange={() => setTermos(!termos)}
                    control={<Checkbox checked={termos} />}
                    label="Li e concordo com os termos e condições de uso"
                    validators={['required']}
                    errorMessages={['Confirme a leitura dos termos!']}
                  />

                  <div className="flex flex-middle">
                    <Button
                      className="capitalize"
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={loading}
                    >
                      Cadastrar
                    </Button>
                    {loading && (
                      <CircularProgress size={24} className="buttonProgress" />
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
          </ValidatorForm>
        </Card>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  // setUser: PropTypes.func.isRequired
});

export default connect(mapStateToProps, {})(SignUp);
