import { MatxLoadable } from 'matx';

const Table = MatxLoadable({
  loader: () => import('./listagem/Table'),
});

const Form = MatxLoadable({
  loader: () => import('./cadastro/Form'),
});

const transmissaoRoutes = [
  {
    path: '/mensagensEnviadas',
    component: Table,
  },
  {
    path: '/transmissao',
    component: Form,
  },
];

export default transmissaoRoutes;
