import React, { Component } from "react";
import axios from 'app/middleware/axios';
import {
  Card,
  Grid,
  Button
} from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { withRouter } from "react-router-dom";

import CONSTANTS from '../../utils/constants';

import { resetPassword } from "../../redux/actions/LoginActions";

class ForgotPassword extends Component {
  state = {
    senha: "",
    senha1: "",
    token: ""
  };
  handleChange = event => {
    event.persist();
    this.setState({
      [event.target.name]: event.target.value
    });
  };
  handleFormSubmit = () => {
    if(this.state.senha !== this.state.senha1) {
      alert("As senhas precisam ser iguais!");
    } else {
      console.log(this.state);
      axios
        .post(`${CONSTANTS.endpoint}/users/changePassword`, this.state)
        .then(response => {
          console.log(response);

          alert("Senha alterada!");
          window.location.href = "/session/signin";
        })
        .catch(error => {
          console.log(error);

          alert("Falha ao alterar a senha!")
        })
        .then(() => {
          // always executed
        });
    }
  };

  componentDidMount() {
    var hash = window.location.search;

    if(hash === "") {
      alert("Hash inválido!");
      window.location.href = "/session/signin";
    }else {
      let token = hash.split("?token=");
      this.setState({
        token: token[1]
      })
    }
  }

  render() {
    let { senha, senha1 } = this.state;

    return (
      <div className="signup flex flex-center w-100 h-100vh">
        <div className="p-8">
          <Card className="signup-card position-relative y-center signin">
            <Grid container>
              <Grid item lg={5} md={5} sm={5} xs={12}>
                <div className="flex flex-center flex-middle h-100">
                  <img src="/assets/images/logo/logo.svg" alt="" />
                </div>
              </Grid>
              <Grid item lg={7} md={7} sm={7} xs={12}>
                <div className="p-36 h-100 bg-light-gray position-relative">
                  <ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>
                    <TextValidator
                      className="mb-24 w-100"
                      variant="outlined"
                      label="Senha"
                      onChange={this.handleChange}
                      type="password"
                      name="senha"
                      value={senha}
                      validators={["required"]}
                      errorMessages={[
                        "Este campo é obrigatório!",
                      ]}
                    />
                    <TextValidator
                      className="mb-24 w-100"
                      variant="outlined"
                      label="Confirmação de Senha"
                      onChange={this.handleChange}
                      type="password"
                      name="senha1"
                      value={senha1}
                      validators={["required"]}
                      errorMessages={[
                        "Este campo é obrigatório!",
                      ]}
                    />
                    <div className="flex flex-middle">
                      <Button variant="contained" color="primary" type="submit">
                        Alterar senha
                      </Button>
                    </div>
                  </ValidatorForm>
                </div>
              </Grid>
            </Grid>
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  resetPassword: PropTypes.func.isRequired,
  login: state.login
});
export default withRouter(
  connect(
    mapStateToProps,
    { resetPassword }
  )(ForgotPassword)
);
