import SignUp from './SignUp';
import SignIn from './SignIn';
import NotFound from './NotFound';
import ForgotPassword from './ForgotPassword';
import Reset from './Reset';
import CancelarConta from './CancelarConta';

const settings = {
  activeLayout: 'layout1',
  layout1Settings: {
    topbar: {
      show: false,
    },
    leftSidebar: {
      show: false,
      mode: 'close',
    },
  },
  layout2Settings: {
    mode: 'full',
    topbar: {
      show: false,
    },
    navbar: { show: false },
  },
  secondarySidebar: { show: false },
  footer: { show: false },
};

const sessionRoutes = [
  {
    path: '/pre-cadastro',
    component: SignUp,
    settings,
  },
  {
    path: '/session/signin',
    component: SignIn,
    settings,
  },
  {
    path: '/session/forgot-password',
    component: ForgotPassword,
    settings,
  },
  {
    path: '/recover',
    component: Reset,
    settings,
  },
  {
    path: '/session/404',
    component: NotFound,
    settings,
  },
  {
    path: '/cancelar_conta',
    component: CancelarConta,
    settings,
  },
];

export default sessionRoutes;
