const endpoint = process.env.REACT_APP_API_URL;
const endpointWebSocket = process.env.REACT_APP_API_WEBSOCKET;
const endpointNew = process.env.REACT_APP_API_URL_NEW;

const version = '1.4.1';
const rowsPerPage = 30;
const paginationDefault = [
  rowsPerPage,
  rowsPerPage * 2,
  rowsPerPage * 4,
  rowsPerPage * 10,
];
const routesReload = ['/dashboard/analytics', '/pedidos', '/pedidosFrete'];

const formaPagamento = [
  {
    id: 'dinheiro',
    label: 'Dinheiro',
  },
  {
    id: 'pagamento_entrega',
    label: 'Pagamento Cartão na Entrega',
  },
  // {
  //   id: 'credito_online',
  //   label: 'Crédito Online',
  // },
  // {
  //   id: 'debito_online',
  //   label: 'Débito Online'
  // },
  // {
  //   id: 'pix',
  //   label: 'Pix'
  // }
];

const constants = {
  endpoint,
  endpointNew,
  endpointWebSocket,
  version,
  rowsPerPage,
  paginationDefault,
  routesReload,
  formaPagamento,
};

export default constants;
