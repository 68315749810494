import axios from 'app/middleware/axios';
import localStorageService from './localStorageService';
import CONSTANTS from '../utils/constants';

class JwtAuthService {
  loginWithEmailAndPassword = (email, password) => {
    return axios
      .post(CONSTANTS.endpoint + '/auth', { email: email, password: password })
      .then(res => {
        this.setSession(res.data.accessToken);

        const user = {
          age: 25,
          displayName: res.data.name,
          email: res.config.data.email,
          photoURL: '/assets/images/user.jpg',
          role: 'ADMIN',
          token: res.data.accessToken,
          userId: res.data.id,
          permissionLevel: parseInt(res.data.permissionLevel),
          idEmpresa: res.data.idEmpresa,
        };

        this.setUser(user);
        return user;
      });
  };

  loginWithToken = usr => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.user);
      }, 1);
    }).then(data => {
      // Token is valid
      this.setSession(usr.token);
      this.setUser(usr);
      return usr;
    });
  };

  logout = () => {
    this.setSession(null);
    this.removeUser();
  };

  // Set token to all http request header, so you don't need to attach everytime
  setSession = token => {
    if (token) {
      localStorage.setItem('jwt_token', token);
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    } else {
      localStorage.removeItem('jwt_token');
      delete axios.defaults.headers.common['Authorization'];
    }
  };

  // Save user to localstorage
  setUser = user => {
    localStorageService.setItem('auth_user', user);
  };
  // Remove user from localstorage
  removeUser = () => {
    localStorage.removeItem('auth_user');
  };
}

export default new JwtAuthService();
