import { MatxLoadable } from "matx";

const Table = MatxLoadable({
  loader: () => import("./listagem/Table")
});

const Form = MatxLoadable({
  loader: () => import("./cadastro/Form")
});

const clientRoutes = [
  {
    path: "/empresas",
    component: Table
  },
  {
    path: "/novaEmpresa",
    component: Form
  },
  {
    path: "/editarEmpresa/:id",
    component: Form
  }
]

export default clientRoutes;