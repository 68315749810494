import { MatxLoadable } from 'matx';

const Table = MatxLoadable({
  loader: () => import('./listagem/Table'),
});

const relatorioRoutes = [
  {
    path: '/relatorio',
    component: Table,
  },
];

export default relatorioRoutes;
