import axios from 'axios';
console.log('AQUI');
axios.interceptors.request.use(
  config => {
    config.headers['app_key'] = '6dbde0a2a0a81af77edd6f3bc81dedbb32a08019';
    return config;
  },
  error => Promise.reject(error)
);

export default axios;
