import { MatxLoadable } from "matx";

const Table = MatxLoadable({
  loader: () => import("./listagem/Table")
});

const Form = MatxLoadable({
  loader: () => import("./cadastro/Form")
});

const categoriasRoutes = [
  {
    path: "/categorias",
    component: Table
  },
  {
    path: "/novaCategoria",
    component: Form
  },
  {
    path: "/editarCategoria/:id",
    component: Form
  }
]

export default categoriasRoutes;