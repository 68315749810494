import { MatxLoadable } from "matx";

const Table = MatxLoadable({
  loader: () => import("./listagem/Table")
});

const Form = MatxLoadable({
  loader: () => import("./cadastro/Form")
});

const freteRoutes = [
  {
    path: "/frete",
    component: Table
  },
  {
    path: "/novoFrete",
    component: Form
  },
  {
    path: "/editarFrete/:id",
    component: Form
  }
]

export default freteRoutes;