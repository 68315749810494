import { MatxLoadable } from "matx";

const Form = MatxLoadable({
  loader: () => import("./cadastro/Form")
});

const profileRoutes = [
  {
    path: "/user-profile",
    component: Form
  }
]

export default profileRoutes;