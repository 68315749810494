import { MatxLoadable } from 'matx';

const Atender = MatxLoadable({
  loader: () => import('./atender/Table'),
});

const Transito = MatxLoadable({
  loader: () => import('./transito/Table'),
});

const Atendidos = MatxLoadable({
  loader: () => import('./atendidos/Table'),
});

const deliveryRoutes = [
  {
    path: '/pedidosFrete',
    component: Atender,
  },
  {
    path: '/pedidosTransito',
    component: Transito,
  },
  {
    path: '/pedidosAtendidos',
    component: Atendidos,
  },
];

export default deliveryRoutes;
