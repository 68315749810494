import "babel-polyfill";
// import cssVars from "css-vars-ponyfill";

// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";

import React from "react";
import ReactDOM from "react-dom";
import "./_index.scss";

import App from "./app/App";

// Sentry.init({
//   dsn: "https://29df588a2672410cb5af16244f6b6fde@o1147344.ingest.sentry.io/6368480",
//   integrations: [new BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

ReactDOM.render(<App />, document.getElementById("root"));