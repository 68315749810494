import React, { Component } from "react";
import axios from 'app/middleware/axios';
import {
  Card,
  Grid,
  Button
} from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { withRouter } from "react-router-dom";

import CONSTANTS from '../../utils/constants';

import { resetPassword } from "../../redux/actions/LoginActions";

class Reset extends Component {
  state = {
    email: ""
  };
  handleChange = event => {
    event.persist();
    this.setState({
      [event.target.name]: event.target.value
    });
  };
  handleFormSubmit = () => {
    axios
      .post(`${CONSTANTS.endpoint}/cancelAccount`, this.state)
      .then(response => {
        console.log(response);

        alert("Solicitação enviada!");
        window.location.href = "/session/signin";
      })
      .catch(error => {
        console.log(error);
        alert("Falha ao realizar solicitação!")
      })
      .then(() => {
        // always executed
      });
  };

  render() {
    let { email } = this.state;

    return (
      <div className="signup flex flex-center w-100 h-100vh">
        <div className="p-8">
          <Card className="signup-card position-relative y-center signin">
            <Grid container>
              <Grid item lg={5} md={5} sm={5} xs={12}>
                <div className="flex flex-center flex-middle h-100">
                  <img src="/assets/images/logo/logo.svg" alt="" />
                </div>
              </Grid>
              <Grid item lg={7} md={7} sm={7} xs={12}>
                <div className="p-36 h-100 bg-light-gray position-relative">
                  <ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>
                    <p>Nós valorizamos seus dados. Caso não queira mais fazer parte da nossa família, digite seu email abaixo e iniciaremos o processo de exclusão dos dados da nossa base.</p>
                    <TextValidator
                      className="mb-24 w-100"
                      variant="outlined"
                      label="E-mail"
                      onChange={this.handleChange}
                      type="email"
                      name="email"
                      value={email}
                      validators={["required"]}
                      errorMessages={[
                        "Este campo é obrigatório!",
                      ]}
                    />
                    <div className="flex flex-middle">
                      <Button variant="contained" color="primary" type="submit">
                        Solicitar cancelamento
                      </Button>
                    </div>
                  </ValidatorForm>
                </div>
              </Grid>
            </Grid>
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  resetPassword: PropTypes.func.isRequired,
  login: state.login
});
export default withRouter(
  connect(
    mapStateToProps,
    { resetPassword }
  )(Reset)
);
