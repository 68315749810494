import { MatxLoadable } from 'matx';

const Table = MatxLoadable({
  loader: () => import('./listagem/Table'),
});

const usuariosRoutes = [
  {
    path: '/appUsers',
    component: Table,
  },
];

export default usuariosRoutes;
