import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { setUserData } from "../redux/actions/UserActions";
import jwtAuthService from "../services/jwtAuthService";
import localStorageService from "../services/localStorageService";
import firebaseAuthService from "../services/firebase/firebaseAuthService";
import history from "history.js";

class Auth extends Component {
  state = {};

  constructor(props) {
    super(props);
    this.checkJwtAuth();
  }

  checkJwtAuth = () => {
    if (['/cancelar_conta', "/recover", "/pre-cadastro", "/session/forgot-password"].includes(window.location.pathname)) return;

    let user = localStorageService.getItem("auth_user");

    if (user !== null && user.token !== undefined) {
      // if (user.token !== undefined) {
      this.props.setUserData(user);
      jwtAuthService.loginWithToken(user);
    } else {
      history.push({
        pathname: "/session/signin"
      });
    }
  };

  checkFirebaseAuth = () => {
    firebaseAuthService.checkAuthStatus(user => {
      if (user) {
        console.log(user.uid);
        console.log(user.email);
        console.log(user.emailVerified);
      } else {
        console.log("not logged in");
      }
    });
  };

  render() {
    const { children } = this.props;
    return <Fragment>{children}</Fragment>;
  }
}

const mapStateToProps = state => ({
  setUserData: PropTypes.func.isRequired,
  login: state.login
});

export default connect(
  mapStateToProps,
  { setUserData }
)(Auth);
