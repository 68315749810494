import { MatxLoadable } from "matx";

const Table = MatxLoadable({
  loader: () => import("./listagem/Table")
});

const TableHistorico = MatxLoadable({
  loader: () => import("./listagem/TableHistorico")
});

const FormHistorico = MatxLoadable({
  loader: () => import("./cadastro/FormHistorico")
});

const comissoesRoutes = [
  {
    path: "/comissoes",
    component: Table
  },
  {
    path: "/comissoesHistorico",
    component: TableHistorico
  },
  {
    path: "/novaComissao",
    component: FormHistorico
  },
  {
    path: "/editarComissao/:id",
    component: FormHistorico
  }
]

export default comissoesRoutes;